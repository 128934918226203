import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Omnigear`}</h1>
    <p>{`Unified `}</p>
    <ul>
      <li parentName="ul">{`Storage`}</li>
      <li parentName="ul">{`CRM`}</li>
      <li parentName="ul">{`Calendar`}</li>
      <li parentName="ul">{`Messaging`}</li>
      <li parentName="ul">{`Events`}</li>
      <li parentName="ul">{`Team`}</li>
    </ul>
    <p>{`API and storage.`}</p>
    <p>{`Providers`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      